import { FOSTableDateCell } from '../common/table/FOSTableDateCell';
import { FOSTableTooltipCell } from '../common/table/FOSTableTooltipCell';
import { FOSTableVesselLinkCell } from '../common/table/FOSTableVesselLinkCell';
import { FOSTableYesNoCell } from '../common/table/FOSTableYesNoCell';
import { FOSTableCurrencyCell } from '../common/table/FOSTableCurrencyCell';
import { FOSTablePeriodCell } from '../common/table/FOSTablePeriodCell';
import { FOSTableNextChartererCell } from '../common/table/FOSTableNextChartererCell';
import { ValuationCellInUSD } from './Valuation/ValutionCellInUSD';

export const SearchColumns = () => {
  return {
    Vessel: [
      {
        Header: 'Global Id',
        accessor: 'global_id',
        default: false,
      },
      {
        Header: 'Attachments',
        accessor: 'attachments',
        default: false,
      },
      {
        Header: 'IMO',
        accessor: 'imo',
        default: true,
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
        default: true,
      },
      {
        Header: 'Vessel Old Name',
        accessor: 'vessel_ex_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_ex_name}
          ></FOSTableVesselLinkCell>
        ),
        default: false,
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        default: true,
      },
      {
        Header: 'Technical Manager',
        accessor: 'technical_manager',
        default: true,
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
        default: true,
      },
      {
        Header: 'Vessel Subtype',
        accessor: 'vessel_subtype',
        default: false,
      },
      {
        Header: 'Vessel North Sea Spot',
        accessor: 'vessel_north_sea_spot',
        default: false,
      },
      {
        Header: 'Ship Status',
        accessor: 'ship_status',
        default: true,
      },
      {
        Header: 'Date In Service',
        accessor: 'date_in_service',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'Shipyard',
        accessor: 'shipyard',
        default: true,
      },
      {
        Header: 'Shipyard Country',
        accessor: 'shipyard_country',
        default: false,
      },
      {
        Header: 'Built',
        accessor: 'built',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Design',
        accessor: 'design',
      },
      {
        Header: 'BHP',
        accessor: 'bhp',
      },
      {
        Header: 'DWT',
        accessor: 'dwt',
      },
      {
        Header: 'Bollard Pull',
        accessor: 'bollard_pull',
        default: false,
      },
      {
        Header: 'Deck Area',
        accessor: 'deck_area',
        default: true,
      },
      {
        Header: 'Deck Area L',
        accessor: 'deck_area_l',
        default: true,
      },
      {
        Header: 'Deck Area B',
        accessor: 'deck_area_b',
        default: true,
      },
      {
        Header: 'Crane Size 1',
        accessor: 'crane_size_1',
        default: true,
      },
      {
        Header: 'Crane Size 2',
        accessor: 'crane_size_2',
        default: true,
      },
      {
        Header: 'Crane Size 3',
        accessor: 'crane_size_3',
        default: true,
      },
      {
        Header: 'DP',
        accessor: 'dp',
        default: true,
      },
      {
        Header: 'Accomondation',
        accessor: 'accomondation',
        default: false,
      },
      {
        Header: 'Beam Type',
        accessor: 'beam_type',
        default: false,
      },
      {
        Header: 'Beam',
        accessor: 'beam',
        default: true,
      },
      {
        Header: 'LOA Type',
        accessor: 'loa_type',
        default: false,
      },
      {
        Header: 'LOA',
        accessor: 'loa',
        default: true,
      },
      {
        Header: 'Mooring',
        accessor: 'mooring',
        default: false,
      },
      {
        Header: 'ROV',
        accessor: 'rov',
        default: false,
      },
      {
        Header: 'Moonpool',
        accessor: 'moonpool',
        default: false,
      },
      {
        Header: 'Helideck',
        accessor: 'helideck',
        default: true,
      },
      {
        Header: 'Wire Capacity',
        accessor: 'wire_capacity',
        default: false,
      },
      {
        Header: 'A Frame',
        accessor: 'a_frame',
        default: false,
      },
      {
        Header: 'Deack Strength',
        accessor: 'deck_strength',
        default: false,
      },
      {
        Header: 'Winch',
        accessor: 'winch',
        default: false,
      },
      {
        Header: 'FIFI',
        accessor: 'fifi',
        default: true,
      },
      {
        Header: 'Oilrec',
        accessor: 'oilrec',
        default: true,
      },
      {
        Header: 'Ice Class',
        accessor: 'ice_class',
        default: true,
      },
      {
        Header: 'FOS Ice Class',
        accessor: 'fos_ice_class',
        default: true,
      },
      {
        Header: 'SPS',
        accessor: 'sps',
        default: true,
      },
      {
        Header: 'Max Speed',
        accessor: 'max_speed',
        default: false,
      },
      {
        Header: 'Standby',
        accessor: 'standby',
        default: false,
      },
      {
        Header: 'Survivors',
        accessor: 'survivors',
        default: false,
      },
      {
        Header: 'Fuel',
        accessor: 'fuel',
        default: false,
      },
      {
        Header: 'Methanol',
        accessor: 'methanol',
        default: false,
      },
      {
        Header: 'Bulk',
        accessor: 'bulk',
        default: false,
      },
      {
        Header: 'Oil Rec',
        accessor: 'oil_rec',
        default: false,
      },
      {
        Header: 'Brine',
        accessor: 'brine',
        default: false,
      },
      {
        Header: 'MUD',
        accessor: 'mud',
        default: false,
      },
      {
        Header: 'Base Oil',
        accessor: 'base_oil',
        default: false,
      },
      {
        Header: 'Special Products',
        accessor: 'special_products',
        default: false,
      },
      {
        Header: 'Ballast water',
        accessor: 'ballast_water',
        default: false,
      },
      {
        Header: 'MEG',
        accessor: 'meg',
        default: false,
      },
      {
        Header: 'Pot Water',
        accessor: 'pot_water',
        default: false,
      },
      {
        Header: 'Hull Number',
        accessor: 'hull_number',
        default: false,
      },
      {
        Header: 'Vessel Class 1',
        accessor: 'vessel_class_1',
        default: false,
      },
      {
        Header: 'Vessel Class 2',
        accessor: 'vessel_class_2',
        default: false,
      },
      {
        Header: 'Flag',
        accessor: 'flag',
        default: true,
      },
      {
        Header: 'Contract Date',
        accessor: 'contract_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'Contract Price Currency',
        accessor: 'contract_price_currency',
        default: true,
      },
      {
        Header: 'Contract Price',
        accessor: 'contract_price',
        default: true,
      },
      {
        Header: 'Dive',
        accessor: 'dive',
        default: true,
      },
      {
        Header: 'Tower',
        accessor: 'tower',
        default: false,
      },
      {
        Header: 'Carousel',
        accessor: 'carousel',
        default: false,
      },
      {
        Header: 'Lay Spread',
        accessor: 'lay_spread',
        default: false,
      },
      {
        Header: 'ERN',
        accessor: 'ern',
        default: false,
      },
      {
        Header: 'KW',
        accessor: 'kw',
        default: false,
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        Cell: (cell: any) => (
          <FOSTableTooltipCell
            value={cell.value}
            textLength={45}
          ></FOSTableTooltipCell>
        ),
        default: true,
      },
      {
        Header: 'Updated',
        accessor: 'updated',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
        default: false,
      },
      {
        Header: 'History Log',
        accessor: 'history_log',
        default: false,
      },
      {
        Header: 'Current Charterer',
        accessor: 'current_charterer',
        default: true,
      },
      {
        Header: 'CC Fix ID',
        accessor: 'current_charterer_fix_id',
        default: false,
      },
      {
        Header: 'CF Type',
        accessor: 'current_fixture_type',
        default: false,
      },
      {
        Header: 'CC Sublet',
        accessor: 'current_charterer_sublet',
        default: false,
      },
      {
        Header: 'CC Sublet Fix ID',
        accessor: 'current_charterer_sublet_fix_id',
        default: false,
      },
      {
        Header: 'NC',
        accessor: 'next_charterer',
        default: false,
        Cell: ({ row }: any) => (
          <FOSTableNextChartererCell
            currenCharterer={row.original.current_charterer}
            nextCharterer={row.original.next_charterer}
            currentChartererDate={row.original.current_charterer_date}
            nextChartererDate={row.original.next_charterer_date}
          />
        ),
      },
      {
        Header: 'NC Fix ID',
        accessor: 'next_charterer_fix_id',
        default: false,
        Cell: ({ row }: any) => (
          <FOSTableNextChartererCell
            currenCharterer={row.original.current_charterer}
            nextCharterer={row.original.next_charterer}
            currentChartererDate={row.original.current_charterer_date}
            nextChartererDate={row.original.next_charterer_date}
          />
        ),
      },
      {
        Header: 'Avail',
        accessor: 'date_available',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: true,
      },
      {
        Header: 'Port',
        accessor: 'port',
        default: false,
      },
      {
        Header: 'Position Comment',
        accessor: 'position_comment',
        default: false,
      },
      {
        Header: 'Position Updated By',
        accessor: 'position_updated_by',
        default: false,
      },
      {
        Header: 'Position Updated On',
        accessor: 'position_updated_on',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        default: false,
      },
      {
        Header: 'Vessel Image',
        accessor: 'vessel_image',
        default: false,
      },
      {
        Header: 'Scrapped',
        accessor: 'scrapped',
        default: false,
      },
      {
        Header: 'Source',
        accessor: 'source',
        default: false,
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'NB Files',
        accessor: 'nb_files',
        default: false,
      },
      {
        Header: 'K Drive Link',
        accessor: 'k_drive_link',
        default: false,
      },
      {
        Header: 'Price',
        accessor: 'price',
        default: false,
      },
      {
        Header: 'Manager',
        accessor: 'manager',
        default: false,
      },
      {
        Header: 'Design Speed',
        accessor: 'design_speed',
        default: false,
      },
      {
        Header: 'Bow Thruster',
        accessor: 'bow_thruster',
        default: false,
      },
      {
        Header: 'Marked Sum',
        accessor: 'marked_sum',
        default: false,
      },
      {
        Header: 'Price Comment',
        accessor: 'price_comment',
        default: false,
      },
      {
        Header: 'Comm Advice Note',
        accessor: 'comm_advice_note',
        default: false,
      },
      {
        Header: 'Region',
        accessor: 'region',
        default: true,
      },
      {
        Header: 'SWL',
        accessor: 'swl',
        default: false,
      },
      {
        Header: 'Stat Dive',
        accessor: 'statdive',
        default: false,
      },
      {
        Header: 'Call Sign',
        accessor: 'callsign',
        default: false,
      },
      {
        Header: 'Main Engines',
        accessor: 'mainengines',
        default: false,
      },
      {
        Header: 'Chainlocker',
        accessor: 'chainlocker',
        default: false,
      },
      {
        Header: 'Workdrum',
        accessor: 'workdrum',
        default: false,
      },
      {
        Header: 'Water Depth',
        accessor: 'waterdept',
        default: false,
      },
      {
        Header: 'Dimensions',
        accessor: 'dimensions',
        default: false,
      },
      {
        Header: 'Cement',
        accessor: 'cement',
        default: false,
      },
      {
        Header: 'Latest Pos',
        accessor: 'latestpos',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'Direct',
        accessor: 'direct',
        default: false,
      },
      {
        Header: 'Type Ice Class',
        accessor: 'typeiceclass',
        default: false,
      },
      {
        Header: 'Engines',
        accessor: 'engines',
        default: false,
      },
      {
        Header: 'Winch BHP',
        accessor: 'winchbhp',
        default: false,
      },
      {
        Header: 'VSPID',
        accessor: 'vspid',
        default: false,
      },
      {
        Header: 'Grabs',
        accessor: 'grabs',
        default: false,
      },
      {
        Header: 'Tuggers',
        accessor: 'tuggers',
        default: false,
      },
      {
        Header: 'Seismic Type',
        accessor: 'seismic_type',
        default: false,
      },
      {
        Header: 'Pos Type',
        accessor: 'postype',
        default: false,
      },
      {
        Header: 'Class 2',
        accessor: 'class2',
        default: false,
      },
      {
        Header: 'Direc',
        accessor: 'direc',
        default: false,
      },
      {
        Header: 'Open Date',
        accessor: 'opendate',
        default: false,
      },
      {
        Header: 'Seismic Text',
        accessor: 'seismic_text',
        default: false,
      },
      {
        Header: 'Crane',
        accessor: 'crane',
        default: false,
      },
      {
        Header: 'Inserted',
        accessor: 'inserted',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'Sypply Type',
        accessor: 'supplytype',
        default: false,
      },
      {
        Header: 'Seismic No',
        accessor: 'seismic_no',
        default: false,
      },
      {
        Header: 'Market',
        accessor: 'market',
        default: false,
      },
      {
        Header: 'LBP',
        accessor: 'lbp',
        default: false,
      },
      {
        Header: 'GT',
        accessor: 'gt',
        default: false,
      },
      {
        Header: 'Cons',
        accessor: 'cons',
        default: false,
      },
      {
        Header: 'Crews',
        accessor: 'crews',
        default: false,
      },
      {
        Header: 'NRT',
        accessor: 'nrt',
        default: false,
      },
      {
        Header: 'Cabins',
        accessor: 'cabins',
        default: false,
      },
      {
        Header: 'Engine Builder',
        accessor: 'enginebuilder',
        default: false,
      },
      {
        Header: 'Engine Make',
        accessor: 'enginemake',
        default: false,
      },
      {
        Header: 'Grabcap',
        accessor: 'grabcap',
        default: false,
      },
      {
        Header: 'Capstrans',
        accessor: 'capstrans',
        default: false,
      },
      {
        Header: 'D Pref',
        accessor: 'dpref',
        default: false,
      },
      {
        Header: 'Next Charterer With Date',
        accessor: 'next_charterer_with_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'Vessel Subsea',
        accessor: 'vessel_subsea',
        default: false,
      },
      {
        Header: 'Controlling Contractor',
        accessor: 'controlling_contractor',
        default: false,
      },
      {
        Header: 'Next Charterer Date',
        accessor: 'next_charterer_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'CC Options Until Date',
        accessor: 'current_charterer_options_until_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'Shipyard Region',
        accessor: 'shipyard_region',
        default: true,
      },
      {
        Header: 'W2W',
        accessor: 'w2w',
        default: false,
      },
      {
        Header: 'Streamer Capacity',
        accessor: 'streamer_capacity',
        default: false,
      },
      {
        Header: 'Battery',
        accessor: 'battery',
        default: true,
      },
      {
        Header: 'Shore Power',
        accessor: 'shore_power',
        default: false,
      },
      {
        Header: 'Dual Fuel',
        accessor: 'dual_fuel',
        default: true,
      },
      {
        Header: 'Draft',
        accessor: 'draft',
        default: false,
      },
    ],
    Fixture: [
      {
        Header: 'Global Id',
        accessor: 'global_id',
        default: true,
      },
      {
        Header: 'IMO',
        accessor: 'imo',
        default: true,
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        default: true,
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
        default: true,
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        default: true,
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Fixture Date',
        accessor: 'fixture_date',
        default: true,
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Rate',
        accessor: 'rate',
        default: true,
        Cell: ({ cell, row }: any) => (
          <FOSTableCurrencyCell
            value={cell.value}
            currency={row.original.currency}
          ></FOSTableCurrencyCell>
        ),
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        default: true,
      },
      {
        Header: 'Period',
        accessor: 'period',
        default: true,
        Cell: ({ cell, row }: any) => (
          <FOSTablePeriodCell
            value={cell.value}
            periodUnits={row.original.period_type}
          ></FOSTablePeriodCell>
        ),
      },
      {
        Header: 'Period Type',
        accessor: 'period_type',
        default: true,
      },
      {
        Header: 'Charterer',
        accessor: 'charterer',
        default: true,
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        default: true,
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'PnC',
        accessor: 'p_and_c',
        default: true,
        Cell: (cell: any) => (
          <FOSTableYesNoCell value={cell.value}></FOSTableYesNoCell>
        ),
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
        default: true,
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        default: false,
      },
      {
        Header: 'Built',
        accessor: 'built',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'dwt_axs',
        accessor: 'dwt_axs',
        default: false,
      },
      {
        Header: 'Type',
        accessor: 'type',
        default: false,
      },
      {
        Header: 'Requirement Subtype',
        accessor: 'requirement_subtype',
        default: false,
      },
      {
        Header: 'To Delivery Date',
        accessor: 'to_delivery_date',
        default: false,
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'User',
        accessor: 'user',
        default: false,
      },
      {
        Header: 'Commodity',
        accessor: 'commodity',
        default: false,
      },
      {
        Header: 'Delivery Port',
        accessor: 'delivery_port',
        default: false,
      },
      {
        Header: 'Redelivery Ports',
        accessor: 'redelivery_ports',
        default: false,
      },
      {
        Header: 'opt_type',
        accessor: 'opt_type',
        default: false,
      },
      {
        Header: 'opt',
        accessor: 'opt',
        default: false,
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        default: true,
      },
      {
        Header: 'To Redelivery Date',
        accessor: 'to_redelivery_date',
        default: false,
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Redelivery Date',
        accessor: 'redelivery_date',
        default: false,
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Source',
        accessor: 'source',
        default: false,
      },
      {
        Header: 'Broker',
        accessor: 'broker',
        default: false,
      },
      {
        Header: 'Rate Type',
        accessor: 'rate_type',
        default: false,
      },
      {
        Header: 'option_curr',
        accessor: 'option_curr',
        default: false,
      },
      {
        Header: 'option_rate_type',
        accessor: 'option_rate_type',
        default: false,
      },
      {
        Header: 'opt_rate',
        accessor: 'opt_rate',
        default: false,
      },
      {
        Header: 'mob_fee_curr',
        accessor: 'mob_fee_curr',
        default: false,
      },
      {
        Header: 'mob_fee',
        accessor: 'mob_fee',
        default: false,
      },
      {
        Header: 'demob_fee_curr',
        accessor: 'demob_fee_curr',
        default: false,
      },
      {
        Header: 'demob_fee',
        accessor: 'demob_fee',
        default: false,
      },
      {
        Header: 'Market',
        accessor: 'market',
        default: false,
      },
      {
        Header: 'Own',
        accessor: 'own',
        default: false,
      },
      {
        Header: 'CP',
        accessor: 'cp',
        default: false,
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        default: false,
      },
      {
        Header: 'Managing Owner',
        accessor: 'managing_owner',
        default: false,
      },
      {
        Header: 'Updated',
        accessor: 'updated',
        default: false,
      },
      {
        Header: 'Region',
        accessor: 'region',
        default: false,
      },
      {
        Header: 'History',
        accessor: 'history',
        default: false,
      },
      {
        Header: 'broker1',
        accessor: 'broker1',
        default: false,
      },
      {
        Header: 'Disch_rate',
        accessor: 'Disch_rate',
        default: false,
      },
      {
        Header: 'Hire',
        accessor: 'hire',
        default: false,
      },
      {
        Header: 'abt',
        accessor: 'abt',
        default: false,
      },
      {
        Header: 'fosid',
        accessor: 'fosid',
        default: false,
      },
      {
        Header: 'spotlist',
        accessor: 'spotlist',
        default: false,
      },
      {
        Header: 'Options Until Date',
        accessor: 'options_until_date',
        default: false,
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'rate_type_rnr',
        accessor: 'rate_type_rnr',
        default: false,
      },
      {
        Header: 'Display Fresh',
        accessor: 'display_fresh',
        default: false,
      },
      {
        Header: 'Is Fresh',
        accessor: 'is_fresh',
        default: false,
      },
      {
        Header: 'Became Fresh Date',
        accessor: 'became_fresh_date',
        default: false,
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
    ],
    Valuation: [
      {
        Header: 'Global ID',
        accessor: 'global_id',
        default: true,
      },
      {
        Header: 'Attachments',
        accessor: 'attachments',
        default: false,
      },
      {
        Header: 'ID',
        accessor: 'id',
        default: false,
      },
      {
        Header: 'Effective Date',
        accessor: 'effective_date',
        default: true,
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'IMO',
        accessor: 'imo',
        default: true,
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        default: true,
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
      },
      {
        Header: 'Source',
        accessor: 'source',
        default: false,
      },
      {
        Header: 'DWT',
        accessor: 'dwt',
        default: false,
      },
      {
        Header: 'Built',
        accessor: 'built',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: true,
      },
      {
        Header: 'Capacity',
        accessor: 'capacity',
        default: false,
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
        default: true,
      },
      {
        Header: 'Price range local from',
        accessor: 'price_local_from',
        default: true,
        Cell: ({ cell, row }: any) => (
          <FOSTableCurrencyCell
            value={cell.value}
            currency={row.original.currency}
          ></FOSTableCurrencyCell>
        ),
      },
      {
        Header: 'Price range local to',
        accessor: 'price_local_to',
        default: true,
        Cell: ({ cell, row }: any) => (
          <FOSTableCurrencyCell
            value={cell.value}
            currency={row.original.currency}
          ></FOSTableCurrencyCell>
        ),
      },
      {
        Header: 'Price Range USD from',
        accessor: 'price_usd_from',
        disableSortBy: true,
        Cell: ({ row }: any) => <ValuationCellInUSD row={row} type='from' />,
      },
      {
        Header: 'Price Range USD to',
        accessor: 'price_usd_to',
        disableSortBy: true,
        Cell: ({ row }: any) => <ValuationCellInUSD row={row} type='to' />,
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        default: true,
        Cell: (cell: any) => (
          <FOSTableTooltipCell
            value={cell.value}
            textLength={45}
          ></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        default: true,
      },
      {
        Header: 'Group owner',
        accessor: 'group_owner',
        default: false,
      },
      {
        Header: 'Issued to',
        accessor: 'issued_to',
        default: false,
      },
      {
        Header: 'Client',
        accessor: 'client',
        default: true,
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        default: false,
      },
      {
        Header: 'Updated on',
        accessor: 'update_on',
        default: false,
      },
      {
        Header: 'BHP',
        accessor: 'bhp',
        default: false,
      },
      {
        Header: 'Dive',
        accessor: 'dive',
        default: false,
      },
    ],
    Sale: [
      {
        Header: 'Global ID',
        accessor: 'global_id',
        default: true,
      },
      {
        Header: 'Attachments',
        accessor: 'attachments',
        default: false,
      },
      {
        Header: 'ID',
        accessor: 'id',
        default: false,
      },
      {
        Header: 'Effective Date',
        accessor: 'effective_date',
        default: true,
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'IMO',
        accessor: 'imo',
        default: true,
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        default: true,
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
      },
      {
        Header: 'Sales type',
        accessor: 'sales_type',
        default: false,
      },
      {
        Header: 'Sales category',
        accessor: 'sales_category',
        default: false,
      },
      {
        Header: 'Enquirer',
        accessor: 'enquirer',
        default: false,
      },
      {
        Header: 'DWT',
        accessor: 'dwt',
        default: false,
      },
      {
        Header: 'Built',
        accessor: 'built',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
        default: false,
      },
      {
        Header: 'Price USD',
        accessor: 'price_usd',
        default: true,
        Cell: ({ cell, row }: any) => (
          <FOSTableCurrencyCell value={cell.value}></FOSTableCurrencyCell>
        ),
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        default: false,
      },
      {
        Header: 'Price local',
        accessor: 'price_local',
        default: false,
        Cell: ({ cell, row }: any) => (
          <FOSTableCurrencyCell
            value={cell.value}
            currency={row.original.currency}
          ></FOSTableCurrencyCell>
        ),
      },
      {
        Header: 'Budget currency',
        accessor: 'budget_currency',
        default: false,
      },
      {
        Header: 'Budget',
        accessor: 'budget',
        default: false,
      },
      {
        Header: 'Asking price curr',
        accessor: 'asking_price_curr',
        default: false,
      },
      {
        Header: 'Asking price',
        accessor: 'asking_price',
        default: false,
      },
      {
        Header: 'Seller',
        accessor: 'seller',
      },
      {
        Header: 'Buyer',
        accessor: 'buyer',
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Source',
        accessor: 'source',
        default: false,
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        default: false,
        Cell: (cell: any) => (
          <FOSTableTooltipCell
            value={cell.value}
            textLength={45}
          ></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'PNC',
        accessor: 'p_and_c',
      },
      {
        Header: 'Group owner',
        accessor: 'group_owner',
        default: false,
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        default: false,
      },
      {
        Header: 'Shipyard',
        accessor: 'shipyard',
        default: true,
      },
      {
        Header: 'Type',
        accessor: 'type',
        default: true,
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
        default: true,
      },
      {
        Header: 'Gear',
        accessor: 'gear',
        default: false,
      },
      {
        Header: 'BHP',
        accessor: 'bhp',
        default: false,
      },
      {
        Header: 'Design',
        accessor: 'design',
        default: false,
      },
      {
        Header: 'Updated',
        accessor: 'updated',
        default: false,
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        default: true,
      },
      {
        Header: 'User',
        accessor: 'User',
        default: false,
      },
      {
        Header: 'Abtrate',
        accessor: 'abtrate',
        default: false,
      },
      {
        Header: 'Dive',
        accessor: 'dive',
        default: false,
      },
      {
        Header: 'Save as Currency',
        accessor: 'currency_save_as',
        default: false,
      },
      {
        Header: 'Via',
        accessor: 'Via',
        default: false,
      },
      {
        Header: 'LDT',
        accessor: 'ldt',
        default: false,
      },
      {
        Header: 'New vessel name',
        accessor: 'new_vessel_name',
        default: false,
      },
      {
        Header: 'New vessel owner',
        accessor: 'new_vessel_owner',
        default: false,
      },
      {
        Header: 'FOSAS Sales',
        accessor: 'fosas_sales',
        default: true,
      },
    ],
  };
};
