import { atom, RecoilState } from 'recoil';

//#region TYPES (TODO: Move)
export interface MultiSelectFormState {
  selectedValues: any[];
  textValue: string;
}

export interface MultiSelectOption {
  label: string;
  value: any;
}

export type MultiSelectOptions = MultiSelectOption[];

export interface RangeFormState {
  from: string;
  to: string;
}

//#endregion

//#region Form Interfaces

export enum FormPart {
  General,
}

export interface GeneralForm {
  salesCategory: MultiSelectOptions;
  salesType: MultiSelectOptions;
  buyer: MultiSelectOptions;
  seller: MultiSelectOptions;
  effectiveDateFrom: Date | null;
  effectiveDateTo: Date | null;
  imoNumber: string;
  vesselName: string;
  owner: string;
  design: MultiSelectOptions;
  vesselSubType: MultiSelectOptions;
  shipyard: MultiSelectOptions;
  builtFrom: Date | null;
  builtTo: Date | null;
  DWT: RangeFormState;
  BHP: RangeFormState;
  dive: MultiSelectOptions;

  fosas_sales: MultiSelectOptions;
}

// --- Default vals ---

export const defaultSaleGeneralForm: GeneralForm = {
  salesCategory: [],
  salesType: [],
  buyer: [],
  seller: [],
  effectiveDateFrom: null,
  effectiveDateTo: null,
  imoNumber: '',
  vesselName: '',
  owner: '',
  design: [],
  vesselSubType: [],
  shipyard: [],
  builtFrom: null,
  builtTo: null,
  DWT: { from: '', to: '' },
  BHP: { from: '', to: '' },
  dive: [],
  fosas_sales: [],
};

// --- Recoil States ---

export const saleSearchGeneralForm: RecoilState<GeneralForm> = atom({
  key: 'saleSearchGeneralForm',
  default: defaultSaleGeneralForm,
});

export const saleSearchResults: RecoilState<any> = atom({
  key: 'saleSearchResults',
  default: [],
});

export const keptSaleSearchResults: RecoilState<any> = atom({
  key: 'saleSearchResults',
  default: [],
});

export const forceSaleSearchState = atom({
  key: 'forceSaleSearchState',
  default: false,
});

//#endregion
