import { atom, RecoilState } from 'recoil';

//#region TYPES (TODO: Move)
export interface MultiSelectFormState {
  selectedValues: any[];
  textValue: string;
}

export interface MultiSelectOption {
  label: string;
  value: any;
}

export type MultiSelectOptions = MultiSelectOption[];

export interface RangeFormState {
  from: string;
  to: string;
}

export interface PositionConstraintFormState {
  topLeft: {
    lat: number;
    lon: number;
  };
  bottomRight: {
    lat: number;
    lon: number;
  };
}
//#endregion

//#region Form Interfaces

export enum FormPart {
  General,
}

export interface GeneralForm {
  deliveryPort: MultiSelectOptions;
  type: MultiSelectOptions;
  region: MultiSelectOptions;
  rate: RangeFormState;
  currency: MultiSelectOptions;
  fixtureDateFrom: Date | null;
  fixtureDateTo: Date | null;
  charterer: MultiSelectOptions;
  own: string | null;
  broker: MultiSelectOptions;
  imoNumber: string;
  vesselName: string;
  owner: string;
  vesselSubType: MultiSelectOptions;
  status: MultiSelectOptions;
  builtFrom: Date | null;
  builtTo: Date | null;
  DWT: RangeFormState;

  isBudgetIndication: boolean;
}

// --- Default vals ---

export const defaultVesselGeneralForm: GeneralForm = {
  deliveryPort: [],
  type: [],
  region: [],
  rate: { from: '', to: '' },
  currency: [],
  fixtureDateFrom: null,
  fixtureDateTo: null,
  charterer: [],
  own: null,
  broker: [],
  imoNumber: '',
  vesselName: '',
  owner: '',
  vesselSubType: [],
  status: [],
  builtFrom: null,
  builtTo: null,
  DWT: { from: '', to: '' },
  isBudgetIndication: false,
};

// --- Recoil States ---

export const fixtureSearchGeneralForm: RecoilState<GeneralForm> = atom({
  key: 'fixtureSearchGeneralForm',
  default: defaultVesselGeneralForm,
});

export const vesselSearchResults: RecoilState<any> = atom({
  key: 'fixtureSearchResults',
  default: [],
});

export const keptFixtureSearchResults: RecoilState<any> = atom({
  key: 'keptFixtureSearchResults',
  default: [],
});

export const forceFixtureSearchState = atom({
  key: 'forceFixtureSearchState',
  default: false,
});

//#endregion
