import { useEffect, useState } from 'react';
import { getExchangeRates } from '../../services/fosService';
import { Row } from 'react-table';

export const ValuationCellInUSD = ({
  row,
  type,
}: {
  row: Row<any>;
  type?: 'to' | 'from';
}) => {
  const valuation = row.original;
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [fromPriceUSD, setFromPriceUSD] = useState<string>('NA');
  const [toPriceUSD, setToPriceUSD] = useState<string>('NA');

  const getPriceUSD = async (
    fromCurrency: string,
    priceLocal: number,
    effectiveDate: string
  ) => {
    try {
      const priceUSD = await getExchangeRates(
        fromCurrency,
        'USD',
        effectiveDate,
        priceLocal
      );
      return priceUSD.rate;
    } catch (err) {
      return 'NA';
    }
  };

  const getUSDPriceFromValuation = async (
    valuation: any,
    type?: 'from' | 'to'
  ) => {
    if (!type || type === 'from') {
      const fromPriceUSD = await getPriceUSD(
        valuation.currency,
        valuation.price_local_from,
        valuation.effective_date
      );
      const formatedPriceUSD = fromPriceUSD.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      setFromPriceUSD(formatedPriceUSD);
    }
    if (!type || type === 'to') {
      const toPriceUSD = await getPriceUSD(
        valuation.currency,
        valuation.price_local_to,
        valuation.effective_date
      );
      const formatedToPriceUSD = toPriceUSD.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      setToPriceUSD(formatedToPriceUSD);
    }

    setIsFetching(false);
  };

  useEffect(() => {
    getUSDPriceFromValuation(valuation, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuation, type]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (type === 'from') {
    return <p>{fromPriceUSD}</p>;
  }
  if (type === 'to') {
    return <p>{toPriceUSD}</p>;
  }
  return (
    <p>
      {fromPriceUSD} - {toPriceUSD}
    </p>
  );
};
