import { useState, useRef, useEffect } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { CurrencyResponse, getRatesAll } from '../api/currency';
import { currencies } from '../datas';
import { CreateSaleForm, createSaleForm } from '../state/createSale';
import { ModalHeader } from './ModalHeader';
import FOSBSBoolean from '../common/FOSBSBoolean';
import FOSBSDatePicker from '../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../common/FOSBSMultiSelectAsync';
import FOSBSTextAreaInput from '../common/FOSBSTextAreaInput';
import FOSBSTextInput from '../common/FOSBSTextInput';
import FOSBSPriceInput from '../common/FOSBSPriceInput';
import FOSBSVesselFinder from '../common/FOSBSVesselFinder';
import { MultiSelectOptions } from '../common/form-elements';
import { convertToSaleCreatePayload } from '../mapper/sale';
import { toast } from 'react-toastify';
import { modalEditorOpen } from '../state/modals';
import { createSale } from '../services/fosService';
import { updatePriceUSD } from '../util';

export const saleTypes = [
  { label: 'Reported sold', value: 'Reported sold' },
  { label: 'Sold', value: 'Sold' },
  { label: 'Failed sale', value: 'Failed sale' },
  { label: 'Sold for Demolition', value: 'Sold for Demolition' },
  { label: 'Demolition', value: 'Demolition' },
  { label: 'Offers Invited', value: 'Offers Invited' },
  { label: 'Requested Price', value: 'Requested Price' },
  { label: 'Contract Price', value: 'Contract Price' },
  { label: 'NewBuilding', value: 'NewBuilding' },
];

interface Props {
  onRequestClose(): void;
}

export const CreateRecentSaleModal = (props: Props) => {
  const [currenciesMapped, setCurrenciesMapped] = useState<MultiSelectOptions>(
    []
  );
  const setModalOpen = useSetRecoilState(modalEditorOpen);

  const [currencyRates, setCurrencyRates] = useState<CurrencyResponse[]>([]);
  const [usdPriceCalculatedInfo, setUsdPriceCalculatedInfo] = useState({
    calculated: false,
    interestRateDate: '',
    reason: '',
  });

  const [form, setForm] = useRecoilState(createSaleForm);
  const latestFormState = useRef(form);
  const latestCurrencyRates = useRef(currencyRates);

  const resetForm = useResetRecoilState(createSaleForm);

  useEffect(() => {
    latestFormState.current = form;
    latestCurrencyRates.current = currencyRates;
  }, [form, currencyRates]);

  useEffect(() => {
    const loadData = async () => {
      // fetch interest rates
      const currencyRatesResp = await getRatesAll(new Date());
      setCurrencyRates(currencyRatesResp);

      // map regions
      setCurrenciesMapped(
        currencies.map((el) => {
          return {
            label: el,
            value: el,
          };
        })
      );
    };

    loadData();
  }, []);

  const setFormStateElement = async (field: string, value: any) => {
    const latest = latestFormState.current as CreateSaleForm;
    const newForm = {
      ...latest,
      [field]: value,
    };
    setForm(newForm);
    latestFormState.current = newForm;

    // if changed field is effectiveDate and priceLocal is set, recalculate priceUSD
    if (newForm.effectiveDate !== undefined && field === 'effectiveDate') {
      // currency must be set for this to make sense

      // fetch interest rates
      const currencyRatesResp = await getRatesAll(newForm.effectiveDate);
      setCurrencyRates(currencyRatesResp);
      const res = updatePriceUSD(
        newForm.currency?.value,
        newForm.priceLocal,
        currencyRatesResp
      );

      setFormStateElement('priceUSD', res.usd);
      setUsdPriceCalculatedInfo(res.calculatedInfo);
    }

    if (['priceLocal', 'currency'].includes(field)) {
      const res = updatePriceUSD(
        newForm.currency?.value,
        newForm.priceLocal,
        latestCurrencyRates.current
      );

      setFormStateElement('priceUSD', res.usd);
      setUsdPriceCalculatedInfo(res.calculatedInfo);
    }
  };

  const doCreate = async () => {
    const finalRequestBody = convertToSaleCreatePayload(
      latestFormState.current
    );

    const response = await createSale(finalRequestBody, 'sale');

    if (response.status) {
      toast.success('Recent sale has been created');
      resetForm();
      setModalOpen(false);
    } else {
      toast.error(response.message);
    }
  };

  if (form === undefined) return <div></div>;

  return (
    <div>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        header={`Register Sale`}
      />
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              General Info
            </h5>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Connected Imo
            </label>
            <div className='col'>
              {form.vesselDetails !== undefined && (
                <>
                  {form.vesselDetails.value.imo === '' && (
                    <p>No vessel selected</p>
                  )}
                  {form.vesselDetails.value.imo !== '' && (
                    <p>{form.vesselDetails.value.imo}</p>
                  )}
                </>
              )}
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Vessel Name
            </label>
            <div className='col'>
              <FOSBSVesselFinder
                name={'vessel_name'}
                stateSetter={(name, value) => {
                  setFormStateElement('vesselDetails', value);
                }}
                value={form.vesselDetails}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'New Name'}
            value={form.newVesselName}
            onChange={(e) => {
              setFormStateElement('newVesselName', e.target.value);
            }}
          />

          <p>Will update vessel name on effective date if filled out.</p>

          <FOSBSTextInput
            label={'New Owner'}
            value={form.newVesselOwner}
            onChange={(e) => {
              setFormStateElement('newVesselOwner', e.target.value);
            }}
          />

          <p>Will update vessel owner on effective date if filled out.</p>

          <FOSBSDatePicker
            label={'Effective Date'}
            value={form.effectiveDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'effectiveDate'}
          />

          <FOSBSPriceInput
            label={'Price Local'}
            value={form.priceLocal}
            onChange={(val) => {
              setFormStateElement('priceLocal', val);
            }}
          />

          <p>Local price showed in millions</p>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Currency
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='currency'
                value={form.currency}
                options={currenciesMapped}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSPriceInput
            label={'Price USD'}
            prefix={'$'}
            value={form.priceUSD}
            onChange={(val) => {
              setFormStateElement('priceUSD', val);
            }}
          />

          {usdPriceCalculatedInfo.calculated && (
            <>
              <strong>Info:</strong> {usdPriceCalculatedInfo.reason}
              <br />
              {usdPriceCalculatedInfo.interestRateDate !== '' && (
                <>
                  <strong>Using rates from:</strong>{' '}
                  {new Date(
                    usdPriceCalculatedInfo.interestRateDate
                  ).toLocaleDateString()}
                </>
              )}
            </>
          )}

          {!usdPriceCalculatedInfo.calculated &&
            usdPriceCalculatedInfo.reason !== '' && (
              <>
                <strong>INFO:</strong> {usdPriceCalculatedInfo.reason}
              </>
            )}

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Type
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='salesType'
                value={form.salesType}
                options={saleTypes}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <div style={{ paddingTop: '1rem' }}>
            <FOSBSTextAreaInput
              label={'Comments'}
              value={form.comments}
              onChange={(e) => {
                setFormStateElement('comments', e.target.value);
              }}
            />
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              Details
            </h5>
          </div>

          <FOSBSTextInput
            label={'Source'}
            value={form.source}
            onChange={(e) => {
              setFormStateElement('source', e.target.value);
            }}
          />

          <FOSBSDatePicker
            label={'Delivery Date'}
            value={form.deliveryDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'deliveryDate'}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Buyer
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='buyer'
                dbTableName='sales'
                name='buyer'
                isMulti={false}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                value={form.buyer}
                isCreatable={true}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Seller
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='seller'
                dbTableName='sales'
                name='seller'
                isMulti={false}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                value={form.seller}
                isCreatable={true}
              />
            </div>
          </div>

          <FOSBSBoolean
            label={'P&C'}
            name={'privateAndConfidential'}
            value={form.privateAndConfidential}
            stateSetter={(name: string, value: string) => {
              setFormStateElement(name, value);
            }}
          />
          <FOSBSBoolean
            label={'FOSAS Sales'}
            name={'FOSASSales'}
            value={form.FOSASSales}
            stateSetter={(name: string, value: string) => {
              setFormStateElement(name, value);
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6' style={{ textAlign: 'right' }}>
          <hr />
          <button
            type='button'
            className='btn btn-secondary btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              resetForm();
              props.onRequestClose();
            }}
          >
            Cancel
          </button>

          <button
            type='button'
            className='btn btn-success btn'
            onClick={() => {
              doCreate();
            }}
          >
            Create sale
          </button>
        </div>
      </div>
    </div>
  );
};
