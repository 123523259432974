import {
  FixtureSearchRequest,
  FixtureSearchRequestGeneralInfo,
} from '../server-types';
import { GeneralForm, RangeFormState } from '../state/searchFixture';
import moment from 'moment';

interface FOSRange {
  from?: number | string | Date;
  to?: number | string | Date;
}

interface InputFormat {
  general: GeneralForm;
}

export const convertToFixtureSearchPayload = (
  input: InputFormat
): FixtureSearchRequest => {
  const mapRange = (source: RangeFormState, target: any, key: any) => {
    const createRange = source.from !== '' || source.to !== '';
    if (!createRange) return; // neither from or to was specified

    // Special handling: If (Built), include last year (map to db values)
    let valuesPrepared = { ...source };

    const targetRange: FOSRange = {};
    if (valuesPrepared.from !== '') targetRange.from = valuesPrepared.from;
    if (valuesPrepared.to !== '') targetRange.to = valuesPrepared.to;
    target[key] = targetRange;
  };

  const mapDateRange = (
    startDate: Date | null,
    endDate: Date | null,
    target: any,
    key: any
  ) => {
    const createRange = startDate !== null || endDate !== null;
    if (!createRange) return; // neither from or to was specified

    let valuesPrepared = { from: startDate, to: endDate };

    const targetRange: FOSRange = {};
    if (valuesPrepared.from !== null)
      targetRange.from = moment(valuesPrepared.from).format('Y-MM-DD');
    if (valuesPrepared.to !== null)
      targetRange.to = moment(valuesPrepared.to).format('Y-MM-DD');
    target[key] = targetRange;
  };

  const generalPayload: FixtureSearchRequestGeneralInfo = {};

  // --- MAP: GENERAL
  // prepare general (str)
  if (input.general.imoNumber !== '')
    generalPayload.imo = Number(input.general.imoNumber);
  if (input.general.vesselName !== '')
    generalPayload.vesselName = input.general.vesselName;
  if (input.general.owner !== '') generalPayload.owner = input.general.owner;
  if (input.general.own !== null) generalPayload.own = input.general.own;
  // pick first (arr to str) (TODO: make these arr to arr later on)
  //if (input.general.deliveryPort.length !== 0) generalPayload.deliveryPort = input.general.deliveryPort[0].value;
  // if (input.general.type.length !== 0) generalPayload.type = input.general.type[0].value;
  // if (input.general.charterer.length !== 0) generalPayload.charterer = input.general.charterer[0].value;
  // if (input.general.region.length !== 0) generalPayload.region = input.general.region[0].value;
  // if (input.general.owner.length !== 0) generalPayload.owner = input.general.owner[0].value;
  // if (input.general.status.length !== 0) generalPayload.status = input.general.status[0].value;
  // if (input.general.broker.length !== 0) generalPayload.broker = input.general.broker[0].value;

  // pick arr (arr to arr)
  if (input.general.deliveryPort.length !== 0)
    generalPayload.deliveryPort = input.general.deliveryPort.map((el) => {
      return el.value;
    }); // map from [{label: string, value: string},...] -> [value,...]
  if (input.general.vesselSubType.length !== 0)
    generalPayload.vesselSubType = input.general.vesselSubType.map((el) => {
      return el.value;
    }); // map from [{label: string, value: string},...] -> [value,...]
  if (input.general.currency.length !== 0)
    generalPayload.currency = input.general.currency.map((el) => {
      return el.value;
    }); // map from [{label: string, value: string},...] -> [value,...]
  if (input.general.charterer.length !== 0)
    generalPayload.charterer = input.general.charterer.map((el) => {
      return el.value;
    });
  if (input.general.broker.length !== 0)
    generalPayload.broker = input.general.broker.map((el) => {
      return el.value;
    });
  if (input.general.status.length !== 0)
    generalPayload.status = input.general.status.map((el) => {
      return el.value;
    });
  if (input.general.region.length !== 0)
    generalPayload.region = input.general.region.map((el) => {
      return el.value;
    });
  if (input.general.type.length !== 0)
    generalPayload.type = input.general.type.map((el) => {
      return el.value;
    });
  // Ranges
  mapRange(input.general.DWT, generalPayload, 'DWT');
  mapRange(input.general.rate, generalPayload, 'rate');
  mapDateRange(
    input.general.builtFrom,
    input.general.builtTo,
    generalPayload,
    'built'
  );
  mapDateRange(
    input.general.fixtureDateFrom,
    input.general.fixtureDateTo,
    generalPayload,
    'fixtureDate'
  );

  generalPayload.isBudgetIndication = input.general.isBudgetIndication;

  return {
    general: generalPayload,
  };
};
