import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Login from './Login';
import NewSearch from './Search/Vessel/NewSearch';
import { default as NewSearchFixture } from './Search/Fixture/NewSearch';
import { default as NewSearchSale } from './Search/Sale/NewSearch';
import { default as NewSearchValuation } from './Search/Valuation/NewSearch';

import { Index } from './Index/Index';
import { NorthSeaSpot } from './Index/NorthSeaSpot';
import { NewBuilding } from './Index/NewBuilding';
import { SNP } from './Index/SNP';
import { Subsea } from './Index/Subsea';
import { OSV } from './Index/OSV';
import { Valuation } from './Index/valuation/Valuation';
import { Admin } from './Index/Admin';
import { Home } from './Index/Home';
import { OpenVessel } from './Index/OpenVessel';
import { VesselValuation } from './Search/Vessel/VesselValuation';
import { BudgetIndication } from './Search/Vessel/BudgetIndication';
import { BulkUploadValuation } from './Index/valuation/BulkUploadValuation';

interface Props {
  loginStateVal: boolean;
  isAdmin: boolean;
}

const RouterRoot = (props: Props) => {
  const { loginStateVal, isAdmin } = props;

  return (
    <Router>
      <Switch>
        <Route exact path='/login'>
          {loginStateVal ? <Redirect to='/dashboard' /> : <Login />}
        </Route>
        <Route exact path='/dashboard'>
          {loginStateVal ? <Index /> : <Redirect to='/login' />}
        </Route>
        <Route exact path='/north-sea-spot'>
          {loginStateVal ? <NorthSeaSpot /> : <Redirect to='/login' />}
        </Route>
        <Route exact path='/new-building'>
          {loginStateVal ? <NewBuilding /> : <Redirect to='/login' />}
        </Route>
        <Route exact path='/snp'>
          {loginStateVal ? <SNP /> : <Redirect to='/login' />}
        </Route>
        <Route exact path='/subsea'>
          {loginStateVal ? <Subsea /> : <Redirect to='/login' />}
        </Route>
        <Route exact path='/osv'>
          {loginStateVal ? <OSV /> : <Redirect to='/login' />}
        </Route>
        <Route exact path='/valuation'>
          {loginStateVal ? <Valuation /> : <Redirect to='/login' />}
        </Route>
        <Route exact path='/valuation/bulk-upload'>
          {loginStateVal ? <BulkUploadValuation /> : <Redirect to='/login' />}
        </Route>
        <Route exact path='/vessel/:imo'>
          {loginStateVal ? <OpenVessel /> : <Redirect to='/login' />}
        </Route>
        <Route exact path='/search/vessel'>
          {loginStateVal ? (
            <>
              <NewSearch cookieKey='vessel-search-columns' />
            </>
          ) : (
            <Redirect to='/login' />
          )}
        </Route>
        <Route exact path='/search/vessel/valuation'>
          {loginStateVal ? (
            <>
              <VesselValuation />
            </>
          ) : (
            <Redirect to='/login' />
          )}
        </Route>
        <Route path='/admin'>
          {loginStateVal && isAdmin ? <Admin /> : <Redirect to='/login' />}
        </Route>
        <Route exact path='/search/fixture'>
          {loginStateVal ? (
            <>
              <NewSearchFixture cookieKey='fixture-search-columns' />
            </>
          ) : (
            <Redirect to='/login' />
          )}
        </Route>
        <Route exact path='/search/budget-indication'>
          {loginStateVal ? (
            <>
              <NewSearchFixture
                cookieKey='fixture-search-columns'
                isBudgetIndication
              />
            </>
          ) : (
            <Redirect to='/login' />
          )}
        </Route>
        <Route exact path='/search/sale'>
          {loginStateVal ? (
            <>
              <NewSearchSale cookieKey='sale-search-columns' />
            </>
          ) : (
            <Redirect to='/login' />
          )}
        </Route>
        <Route exact path='/search/valuation'>
          {loginStateVal ? (
            <>
              <NewSearchValuation cookieKey='valuation-search-columns' />
            </>
          ) : (
            <Redirect to='/login' />
          )}
        </Route>
        <Route exact path='/search/vessel/budget-indication'>
          {loginStateVal ? (
            <>
              <BudgetIndication />
            </>
          ) : (
            <Redirect to='/login' />
          )}
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};

export default RouterRoot;
