import { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { CurrencyResponse, getRatesAll } from '../api/currency';
import { currencies } from '../datas';
import {
  valuationGlobalId,
  valuationState,
  valuationEditFormState,
  EditValuationForm,
} from '../state/openValuation';

import FOSBSDatePicker from '../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../common/FOSBSMultiSelect';
import FOSBSTextAreaInput from '../common/FOSBSTextAreaInput';
import FOSBSTextInput from '../common/FOSBSTextInput';
import FOSBSVesselFinder from '../common/FOSBSVesselFinder';
import { MultiSelectOptions } from '../common/form-elements';

import {
  convertDbValuationToEditValuationForm,
  convertToValuationEditPayload,
} from '../mapper/valuation-edit';
import { toast } from 'react-toastify';
import { modalEditorOpen } from '../state/modals';
import { shallowFormDiff } from '../util';
import {
  getRecentValuation,
  updateValuation,
  deleteValuation,
} from '../services/fosService';
import FOSBSRange from '../common/FOSBSRange';

interface Props {
  onRequestClose(): void;
}

const generateValuationEditState = (oldValuation: any, newValuation: any) => {
  if (oldValuation === undefined || newValuation === undefined) {
    return null;
  }

  const oldPayload = convertToValuationEditPayload(oldValuation);
  const newPayload = convertToValuationEditPayload(newValuation);

  const stateChange = shallowFormDiff(oldPayload, newPayload);

  return stateChange;
};

export const EditRecentValuationForm = (props: Props) => {
  const [currenciesMapped, setCurrenciesMapped] = useState<MultiSelectOptions>(
    []
  );
  const [valuationId, setValuationlId] = useRecoilState(valuationGlobalId);

  const setModalOpen = useSetRecoilState(modalEditorOpen);

  const [currencyRates, setCurrencyRates] = useState<CurrencyResponse[]>([]);
  const [usdPriceCalculatedInfo, setUsdPriceCalculatedInfo] = useState({
    calculated: false,
    interestRateDate: '',
    reason: '',
  });

  const [valuation, setValuation] = useRecoilState(valuationState);
  const [form, setForm] = useRecoilState(valuationEditFormState);
  const latestFormState = useRef(form);
  const latestCurrencyRates = useRef(currencyRates);

  const resetValuation = useResetRecoilState(valuationState);
  const resetForm = useResetRecoilState(valuationEditFormState);

  let stateChange = generateValuationEditState(valuation, form);
  const untouched = stateChange === undefined || stateChange === null;

  useEffect(() => {
    latestFormState.current = form;
    latestCurrencyRates.current = currencyRates;
  }, [form, currencyRates]);

  useEffect(() => {
    const loadData = async () => {
      const lookupResp = await getRecentValuation(valuationId);
      const valuation = convertDbValuationToEditValuationForm(lookupResp[0]);

      // fetch interest rates
      const currencyRatesResp = await getRatesAll(valuation.effectiveDate);
      setCurrencyRates(currencyRatesResp);
      setForm(valuation);
      setValuation(valuation);

      // map regions
      setCurrenciesMapped(
        currencies.map((el) => {
          return {
            label: el,
            value: el,
          };
        })
      );
    };

    loadData();
  }, [valuationId]);

  const setFormStateElement = async (field: string, value: any) => {
    const latest = latestFormState.current as EditValuationForm;
    const newForm = {
      ...latest,
      [field]: value,
    };
    setForm(newForm);
    latestFormState.current = newForm;

    // if changed field is effectiveDate and priceLocal is set, recalculate priceUSD
    if (newForm.effectiveDate !== undefined && field === 'effectiveDate') {
      // currency must be set for this to make sense

      // fetch interest rates
      const currencyRatesResp = await getRatesAll(newForm.effectiveDate);
      setCurrencyRates(currencyRatesResp);

      updatePriceUSD(newForm, currencyRatesResp);
    }

    if (['priceLocal', 'currency'].includes(field)) {
      updatePriceUSD(newForm, latestCurrencyRates.current);
    }
  };

  const updatePriceUSD = (
    newForm: EditValuationForm,
    currencyRates: CurrencyResponse[]
  ) => {
    if (newForm.currency === undefined) return;
    const userInputCurrency = newForm.currency.value as string;

    // If USD, skip calculation
    if (userInputCurrency.toLowerCase() === 'usd') {
      setFormStateElement('priceUSD', {
        from: Number(newForm.priceLocal.from) * 1000000,
        to: Number(newForm.priceLocal.to) * 1000000,
      }); // reset it
      setUsdPriceCalculatedInfo({
        calculated: true,
        interestRateDate: '',
        reason: 'USD->USD',
      });
      return;
    }

    // Calculation part
    const resetWithMsg = (reason: string) => {
      setFormStateElement('priceUSD', { from: '', to: '' }); // reset it
      setUsdPriceCalculatedInfo({
        calculated: false,
        interestRateDate: '',
        reason,
      });
    };

    // reset if anything fails or is unavailable
    if (currencyRates.length == 0) {
      resetWithMsg('');
      return;
    }

    const usdNOK = currencyRates.find((el) => {
      return el.currency.toLowerCase() === 'usd';
    });
    if (usdNOK === undefined) {
      resetWithMsg('Missing USD');
      return;
    }

    // If NOK is used, do simple direct calculation
    if (userInputCurrency.toLowerCase() === 'nok') {
      // Currency is found!
      const newUSDPrice = {
        from: '',
        to: '',
      };

      // Check from:
      if (
        newForm.priceLocal.from !== '' &&
        !isNaN(Number(newForm.priceLocal.from))
      ) {
        newUSDPrice.from = (
          (Number(newForm.priceLocal.from) / usdNOK.rate) *
          1000000
        ).toFixed(2);
      }

      // Check to:
      if (
        newForm.priceLocal.to !== '' &&
        !isNaN(Number(newForm.priceLocal.to))
      ) {
        newUSDPrice.to = (
          (Number(newForm.priceLocal.to) / usdNOK.rate) *
          1000000
        ).toFixed(2);
      }

      // Finally update vals!
      setFormStateElement('priceUSD', newUSDPrice);
      setUsdPriceCalculatedInfo({
        calculated: true,
        interestRateDate: usdNOK.rate_date,
        reason: '',
      });
      return;
    }

    // Not NOK -- Do INDIRECT calculation via USDNOK

    const findCurrencyRate = currencyRates.find((el) => {
      return el.currency.toLowerCase() === userInputCurrency.toLowerCase(); // TODO: NOK->USD only supported. //form.currency?.value.toLowerCase();
    });

    if (findCurrencyRate === undefined) {
      setFormStateElement('priceUSD', { from: '', to: '' }); // reset it
      setUsdPriceCalculatedInfo({
        calculated: false,
        interestRateDate: '',
        reason: `Found no currency rate for ${userInputCurrency}`,
      });
      return;
    }

    // do indirect conversion to usd!
    const indirectRate = Number(usdNOK.rate) / Number(findCurrencyRate.rate);

    // Currency is found!
    const newUSDPrice = {
      from: '',
      to: '',
    };

    // Check from:
    if (
      newForm.priceLocal.from !== '' &&
      !isNaN(Number(newForm.priceLocal.from))
    ) {
      newUSDPrice.from = (
        (Number(newForm.priceLocal.from) / indirectRate) *
        1000000
      ).toFixed(2);
    }

    // Check to:
    if (newForm.priceLocal.to !== '' && !isNaN(Number(newForm.priceLocal.to))) {
      newUSDPrice.to = (
        (Number(newForm.priceLocal.to) / indirectRate) *
        1000000
      ).toFixed(2);
    }

    // Finally update vals!
    setFormStateElement('priceUSD', newUSDPrice);
    setUsdPriceCalculatedInfo({
      calculated: true,
      interestRateDate: findCurrencyRate.rate_date,
      reason: `Calculated ${userInputCurrency}/USD rate (${(1 / indirectRate).toFixed(2)}) indirectly via ${userInputCurrency}/NOK -> NOK/USD`,
    });
    return;
  };

  const doUpdate = async () => {
    stateChange = generateValuationEditState(
      valuation,
      latestFormState.current
    );

    if (stateChange === undefined) return;

    await updateValuation(valuationId, stateChange);

    toast.success('Valuation has been updated');
    resetForm();
    resetValuation();
    setModalOpen(false);
  };

  const doDelete = async () => {
    const r = window.confirm(
      'Are you sure you want to delete recent valuation?'
    );
    if (!r) return;

    const response = await deleteValuation(valuationId);

    if (response.status) {
      toast.success('Valuation has been deleted');
      resetForm();
      resetValuation();
      setModalOpen(false);
    } else {
      toast.error(response.message);
    }
  };

  if (form === undefined) return <div></div>;

  return (
    <>
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              General Info
            </h5>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Connected Imo
            </label>
            <div className='col'>
              <label htmlFor='inputEmail3' className='col-form-label'>
                {form.imo}
              </label>
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Vessel Name
            </label>
            <div className='col'>
              <FOSBSVesselFinder
                name={'vessel_name'}
                stateSetter={(name, value) => {
                  setFormStateElement('vesselDetails', value);
                }}
                value={form.vesselDetails}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSDatePicker
            label={'Effective Date'}
            value={form.effectiveDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'effectiveDate'}
          />

          <FOSBSRange
            label='Price Local (lo-hi)'
            name='priceLocal'
            stateSetter={(name: string, value: string) => {
              setFormStateElement('priceLocal', value);
            }}
            value={form.priceLocal}
          />

          <p>Local price showed in millions</p>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Currency
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='currency'
                value={form.currency}
                options={currenciesMapped}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSRange
            label='Price USD (lo-hi)'
            name='priceUSD'
            stateSetter={(name: string, value: any) => {
              setFormStateElement('priceUSD', value);
            }}
            value={form.priceUSD}
          />

          {usdPriceCalculatedInfo.calculated && (
            <>
              <strong>Info:</strong> {usdPriceCalculatedInfo.reason}
              <br />
              {usdPriceCalculatedInfo.interestRateDate !== '' && (
                <>
                  <strong>Using rates from:</strong>{' '}
                  {new Date(
                    usdPriceCalculatedInfo.interestRateDate
                  ).toLocaleDateString()}
                </>
              )}
            </>
          )}
          {!usdPriceCalculatedInfo.calculated &&
            usdPriceCalculatedInfo.reason !== '' && (
              <>
                <strong>INFO:</strong> {usdPriceCalculatedInfo.reason}
              </>
            )}

          {usdPriceCalculatedInfo.calculated && (
            <>
              <strong>Info:</strong> {usdPriceCalculatedInfo.reason}
              <br />
              {usdPriceCalculatedInfo.interestRateDate !== '' && (
                <>
                  <strong>Using rates from:</strong>{' '}
                  {new Date(
                    usdPriceCalculatedInfo.interestRateDate
                  ).toLocaleDateString()}
                </>
              )}
            </>
          )}

          {!usdPriceCalculatedInfo.calculated &&
            usdPriceCalculatedInfo.reason !== '' && (
              <>
                <strong>INFO:</strong> {usdPriceCalculatedInfo.reason}
              </>
            )}

          <div style={{ paddingTop: '1rem' }}>
            <FOSBSTextAreaInput
              label={'Comments'}
              value={form.comments}
              onChange={(e) => {
                setFormStateElement('comments', e.target.value);
              }}
            />
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              Details
            </h5>
          </div>

          <FOSBSTextInput
            label={'Source'}
            value={form.source}
            onChange={(e) => {
              setFormStateElement('source', e.target.value);
            }}
          />

          <FOSBSTextInput
            label={'Client'}
            value={form.client}
            onChange={(e) => {
              setFormStateElement('client', e.target.value);
            }}
          />

          <FOSBSTextInput
            label={'Issued to'}
            value={form.issuedTo}
            onChange={(e) => {
              setFormStateElement('issuedTo', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Updated
            </label>
            <div className='col'>
              <label htmlFor='inputEmail3' className='col-form-label'>
                {form.update_on
                  ? moment(form.update_on).format('DD.MM.yyyy HH:mm:ss')
                  : 'NA'}
              </label>
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Updated by
            </label>
            <div className='col'>
              <label htmlFor='inputEmail3' className='col-form-label'>
                {form.updatedBy}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6' style={{ textAlign: 'right' }}>
          <hr />
          <button
            type='button'
            className='btn btn-secondary btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              resetForm();
              props.onRequestClose();
            }}
          >
            Cancel
          </button>

          <button
            type='button'
            className='btn btn-success btn'
            disabled={untouched}
            onClick={() => {
              doUpdate();
            }}
          >
            Update
          </button>
        </div>
        <div className='col-sm-6' style={{ textAlign: 'right' }}>
          <hr />
          <button
            type='button'
            className='btn btn-danger btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              doDelete();
            }}
          >
            Delete valuation
          </button>
        </div>
      </div>
    </>
  );
};
